.web-wrapper {
    .web-main {
        .web-about {
            margin-top: 3rem;
            margin-bottom: 3rem;
            .web-about-image {
                img {
                    width: 100%;
                    height: auto;
                    object-fit: fill;
                    border-radius: 15px;
                }
            }
            .web-about-text {
                span {
                    font-size: 1.5rem;
                    font-weight: 600;
                    color: $link-secondary;
                    font-family: $font-family-caveat;
                }
                h2 {
                    font-size: 2rem;
                    font-weight: 900;
                    color: $link-dark;
                    margin: 0.5rem 0;
                    text-transform: capitalize;
                }
                h3 {
                    font-size: 2rem;
                    font-weight: 700;
                    color: $link-secondary;
                    margin: 0.5rem 0;
                    font-family: $font-family-caveat;
                    text-transform: capitalize;
                    border-bottom: 2px solid $link-smoke;
                }
                p {
                    margin-top: 1rem;
                    font-size: 1rem;
                    color: rgba($color: $link-dark, $alpha: 0.7);
                }
            }
            .web-about-team {
                margin-top: 2rem;
                .web-about-team-title {
                    h2 {
                        font-size: 2rem;
                        font-weight: 900;
                        color: $link-dark;
                        margin: 1rem 0;
                        text-transform: capitalize;
                        border-bottom: 2px solid $link-secondary;
                    }
                }
                .web-about-team-images {
                    margin: 1rem 0;
                    .web-about-team-image {
                        margin-bottom: 1rem;
                        img {
                            width: 100%;
                            height: auto;
                            object-fit: fill;
                            border-radius: 15px 15px 0 0;
                        }
                        border: 1px solid $link-primary;
                        border-bottom: 3px solid $link-primary;
                        border-radius: 15px 15px 0 0;
                        position: relative;
                        .web-about-team-name {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            width: 100%;
                            height: fit-content;
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            flex-direction: column;
                            strong {
                                border-radius: 0px 10px 0 0;
                                background-color: $link-secondary;
                                color: #fff;
                                width: fit-content;
                                padding: 0.5rem 1rem;
                                font-size: 0.8rem;
                                position: relative;
                                &::after{
                                    content: '';
                                    position: absolute;
                                    width: 80%;
                                    height: 1px;
                                    bottom: 0;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    border-bottom: 1px solid $link-primary;
                                }
                            }
                            span {
                                padding: 0.5rem 1rem;
                                color: #fff;
                                background-color: $link-secondary;
                                width: 100%;
                                font-size: 1.1rem;
                                font-weight: 700;
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }
            .web-about-company{
                .col-lg-6{
                    img{
                        width: 75%;
                    }
                }
                .col-lg-12{
                    img{
                        width: 100%;
                    }
                }
            }
        }
    }
}


@media(min-width:992px){
    .web-wrapper {
        .web-main {
            .web-about {
                margin-top: 6rem;
                .web-about-text {
                    h2 {
                        font-size: 3rem;
                    }
                    h3 {
                        font-size: 2.5rem;
                    }
                }
                .web-about-team {
                    margin-top: 3rem;
                    .web-about-team-title {
                        h2 {
                            font-size: 3rem;
                        }
                    }
                    .web-about-team-images {
                        margin: 1rem 0;
                        .web-about-team-image {
                            margin-bottom: 0;
                        }
                    }
                }
                .web-about-company{
                    .col-lg-6{
                        img{
                            width: 50%;
                        }
                    }
                    .col-lg-12{
                        img{
                            width: 50%;
                        }
                    }
                }
            }
        }
    }

}
