// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-family-caveat: 'Caveat', cursive;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// color

$link-smoke: #f0efec;
$link-light: #f8fafc;
$link-dark: #2e2e2e;
$link-gray:#6c757d;
$link-primary: #be914d;
// $link-primary: #965a3e;
$link-secondary: #6c971f;

$link-pink: #f8b3b4;
$link-gray: #6c757d;
$link-green: #007200;
$link-red: #d81159;
$web-nav-height :8rem;
$menubar-height: 3.5rem;
