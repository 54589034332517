.web-wrapper {
    .web-header {
        background-color: #fff;
        position: relative;
        z-index: 1200;
        // &::after {
        //     content: "";
        //     width: 100%;
        //     height: 17px;
        //     position: absolute;
        //     bottom: -15px;
        //     left: 0;
        //     right: 0;
        //     background-image: url("");
        //     background-position: center right;
        //     background-size: cover;
        // }
        .web-header-wrapper {
            max-width: 2000px;
            margin: 0 auto;
            .web-header-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: $link-smoke;
                padding: 0.5rem 1rem;
                .web-social-icons {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 1rem;
                    li {
                        background-color: $link-secondary;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 25px;
                        height: 25px;
                        a {
                            color: #fff;
                            &:hover {
                                color: $link-primary;
                            }
                        }
                    }
                }
                .web-header-top-mail {
                    i {
                        color: $link-secondary;
                    }
                    color: $link-primary;
                    &:hover {
                        color: $link-secondary;
                    }
                }
            }
            .web-navbar {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0.5rem 1rem;
                .web-nav {
                    &-list {
                        display: none;
                    }
                    &-call {
                        color: $link-primary;
                        cursor: pointer;
                        i {
                            font-size: 1.5rem;
                        }
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 0.5rem;
                        strong {
                            display: none;
                        }

                    }
                }
            }
        }
    }
}
@media (max-width: 991.99px) {
    .web-wrapper {
        .web-header {
            // &::after {
            //     bottom: -10px;
            // }
            .web-header-wrapper {
                .web-navbar {
                    .web-nav {
                        visibility: hidden;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        height: fit-content;
                        background-color: #fff;
                        &.show {
                            visibility: visible;
                        }
                        &-list {
                            display: flex;
                            flex-direction: column;
                            padding: 1rem 2rem;
                            padding-bottom: 2rem;
                            border-top: 2px solid $link-smoke;
                        }
                        &-item {
                            padding: 1rem 0;
                            border-bottom: 1px solid $link-primary;
                        }
                        &-link {
                            text-transform: uppercase;
                            font-weight: 600;
                            color: $link-dark;
                            position: relative;
                            &.active {
                                color: $link-secondary;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 992px) {
    .web-wrapper {
        .web-header {
            // &::after {
            //     background-position: center center;
            // }
            .web-header-wrapper {
                .web-header-top {
                    padding: 0.5rem 3rem;
                }
                .web-navbar {
                    padding: 0.5rem 3rem;
                    .web-nav {
                        &-list {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 2rem;
                        }
                        &-link {
                            text-transform: uppercase;
                            font-weight: 600;
                            color: $link-dark;
                            position: relative;
                            &.active {
                                color: $link-secondary;
                                &::after {
                                    content: "";
                                    position: absolute;
                                    bottom: 0;
                                    left: 50%;
                                    transform: translate(-50%, 75%);
                                    width: 30px;
                                    height: 30px;
                                    background:url('/images/favicon.ico') no-repeat center center;
                                    background-size: contain;
                                }
                            }
                            &:hover {
                                color: $link-primary;
                            }
                        }
                        &-call {
                            padding: 0.5rem 1rem;
                            border-radius: 5px;
                            background-color: $link-dark;
                            width: 100%;
                            max-width: 250px;
                            position: relative;
                            overflow: hidden;
                            z-index: 0;
                            color: #fff;
                            i {
                                font-size: 2rem;
                                z-index: 2;
                            }
                            justify-content: space-between;
                            span,
                            strong {
                                display: block;
                                z-index: 2;
                            }
                            strong {
                                font-size: 1.2rem;
                                letter-spacing: 1px;
                                font-weight: 700;
                            }


                            &:hover{
                                &::before{
                                    transition: transform 0.5s ease-in-out;
                                    transform: translateX(-120%) skewX(60deg)
                                }
                                &::after{
                                    transition: transform 0.5s ease-in-out;
                                    transform: translateX(120%) skewX(60deg);
                                }
                                i{
                                    transition: all 0.5s ease-in-out;
                                    color: $link-primary;
                                }
                                strong{
                                    transition: all 0.5s ease-in-out;
                                    color: $link-secondary;
                                }
                            }
                            &::before{
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 55%;
                                height: 100%;
                                background-color: $link-primary;
                                z-index: 1;
                                transition: transform 0.5s ease-in-out;
                            }
                            &::after{
                                content: '';
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 55%;
                                height: 100%;
                                background-color: $link-primary;
                                z-index: 1;
                                transition: transform 0.5s ease-in-out;
                            }
                        }
                    }
                }
            }
        }
    }
}
