.web-footer {
    .web-footer-wrapper {
        background-color: $link-dark;
        padding: 1rem 0;
        .web-footer-logo {
            position: relative;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 75% !important;
                }
            }
        }
        a {
            &:hover {
                color: $link-secondary !important;
            }
        }
        .web-footer-links {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            span {
                color: $link-smoke;
            }
            .web-nav-list {
                display: flex;
                gap: 1rem;
                .web-nav-link {
                    color: #fff;
                    text-transform: capitalize;
                }
            }
        }
        .web-footer-icons {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            a {
                color: #fff;
                i {
                    font-size: 1rem;
                }
            }
            color: #fff;
            .web-social-icons {
                display: flex;
                gap: 1rem;
            }
        }
    }
}

@media (min-width: 992px) {
    .web-footer {
        .web-footer-wrapper {
            .web-footer-logo {
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    width: 2px;
                    height: 90%;
                    background-color: $link-primary;
                }
            }
        }
    }
}
