.web-wrapper {
    .web-main {
        .web-home {
            .web-home-hero{
                .carousel-control-prev,
                .carousel-control-next{
                    font-size: 2.5rem;
                    font-weight: 900;
                    color: #fff;
                }
            }
            .web-home-about {
                width: 100%;
                overflow: hidden;
                .web-home-about-image {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-end;
                    position: relative;
                    z-index: 0;
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: -50%;
                        background-color: $link-primary;
                        width: 100%;
                        height: 100%;
                        z-index: -1;
                    }
                    img {
                        z-index: 1;
                        width: 95%;
                        border-radius: 15px;
                        margin-top: 10vh;
                    }
                }
                .web-home-about-text {
                    margin-top: 10vh;
                    span {
                        font-size: 1rem;
                        font-weight: 600;
                        color: $link-secondary;
                    }
                    h1 {
                        font-size: 2.3rem;
                        font-weight: 800;
                        color: $link-dark;
                    }
                    h2 {
                        font-size: 1.5rem;
                        font-weight: 800;
                        color: $link-secondary;
                        font-family: $font-family-caveat;
                        margin: 1rem 0;
                    }
                    p {
                        margin-top: 2rem;
                        font-size: 1rem;
                        color: rgba($color: $link-dark, $alpha: 0.7);
                    }
                }
                .web-home-about-more {
                    margin-top: 2rem;
                    position: relative;
                    background-color: $link-dark;
                    border-radius: 50px;
                    padding: 1rem;
                    padding-left: 3rem;
                    color: #fff;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 2rem;
                    width: fit-content;
                    z-index: 0;
                    overflow: hidden;
                    &:hover {
                        &::before {
                            transition: transform 0.5s ease-in-out;
                            transform: translateX(-110%) skewX(60deg);
                        }
                        &::after {
                            transition: transform 0.5s ease-in-out;
                            transform: translateX(110%) skewX(60deg);
                        }
                        span {
                            transition: all 0.5s ease-in-out;
                            background-color: #fff;
                            color: $link-secondary;
                        }
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 52%;
                        height: 100%;
                        background-color: $link-secondary;
                        z-index: 1;
                        transition: transform 0.5s ease-in-out;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 52%;
                        height: 100%;
                        background-color: $link-secondary;
                        z-index: 1;
                        transition: transform 0.5s ease-in-out;
                    }
                    strong {
                        font-size: 1rem;
                        z-index: 2;
                    }
                    span {
                        z-index: 2;
                        border-radius: 100%;
                        background-color: $link-primary;
                        color: $link-dark;
                        width: 40px;
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: all 0.5s ease-in-out;
                    }
                }
            }

            .web-home-products {
                margin-top: 6rem;
                margin-bottom: 6rem;
                background: url("../../../img/home-products-bg.jpg") no-repeat
                    center top;
                background-size: 125% 65%;
                .products-title {
                    margin-top: 3rem;
                    h2 {
                        font-size: 1.5rem;
                        width: 75%;
                        margin: 0 auto;
                        font-weight: 700;
                        color: $link-primary;
                        text-transform: uppercase;
                        span {
                            color: $link-secondary;
                        }
                    }
                }
                .web-home-products-wrapper {
                    padding-top: 2rem;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    .web-home-products-item {
                        background-color: #fff;
                        border-radius: 15px;
                        border: 1px solid transparent;
                        transition: all 1s ease-in-out;
                        width: fit-content;
                        position: relative;
                        display: flex !important;
                        justify-content: space-between;
                        align-items: center;
                        overflow: hidden;
                        flex-direction: column;
                        .product-image {
                            width: 250px;
                            height: 300px;
                            position: relative;
                            border-radius: 15px;
                            background-color: $link-smoke;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            object-fit: fill;
                            overflow: hidden;
                            img {
                                width: 50%;
                                object-fit: fill;
                                transition: all 1s ease-in-out;
                            }
                        }
                        .product-name {
                            width: 100%;
                            text-align: center;
                            padding: 1rem 0;
                            span {
                                text-align: center;
                                color: $link-dark;
                            }
                        }
                        &:hover {
                            transition: all 1s ease-in-out;
                            border-color: $link-primary;
                            .product-image {
                                img {
                                    transform: scale(1.1);
                                }
                            }
                        }
                    }
                }
                .slick-slide {
                    padding: 1rem !important;
                }
                .slick-arrow {
                    position: absolute;
                    bottom: 0;
                    border: none;
                    color: $link-dark;
                    width: 40px;
                    height: 40px;
                    padding: 8px;

                    border-radius: 100%;
                    background-color: #fff;
                    transition: all 0.3s ease-in-out;
                    cursor: pointer;
                    &:hover {
                        background-color: $link-secondary;
                    }
                    &.slick-prev {
                        left: 35%;
                        transform: translate(-50%, 100%);
                    }
                    &.slick-next {
                        right: 25%;
                        transform: translate(-50%, 100%);
                    }
                }
            }
            .web-home-vision{
                margin-top: 6rem;
                margin-bottom: 5rem;
                h2{
                    text-transform: uppercase;
                    color: $link-dark;
                    font-weight: 900;
                    font-size: 1.2rem;
                }
                h3{
                    color: $link-secondary;
                    font-size: 1.5rem;
                    font-weight: 700;
                }
                .web-home-vison-image{
                    width: 100%;
                    height: 40vh;
                    background-repeat: no-repeat;
                    background-position: center right;
                    background-size: contain;
                    border-radius: 5px;
                }
                .web-home-vision-text{
                    .web-home-vision-text-item{
                        align-items: center;
                        .text-item-icon{
                            width: 100px;
                            height: 100px;
                            border-radius: 5px;
                            border: 2px solid $link-primary;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img{
                                width: 100%;
                            }
                        }
                        h4{
                            font-weight: 800;
                            font-size: 1.1rem;
                            color: $link-dark;
                        }
                    }
                }
            }
            .web-home-banner{
                width: 100%;
                height: 300px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
}

@media (min-width: 552px) {
    .web-wrapper {
        .web-main {
            .web-home {
                .web-home-products {
                    background-size: 110% 65%;
                    .products-title{
                        h2{
                            width: 100%;
                            font-size: 2rem;
                        }
                    }
                }
                .web-home-vision{
                    h3{
                        font-size: 1.8rem;
                        font-weight: 800;
                    }
                    .web-home-vison-image{
                        height: 60vh;
                        background-size: cover;
                    }

                }
            }
        }
    }
}
@media (min-width: 992px) {
    .web-wrapper {
        .web-main {
            .web-home {
                .web-home-hero{
                    .web-home-hero-image{
                        height: 500px;
                    }
                }
                .web-home-products {
                    background-size: 100% 65%;
                    .products-title{
                        h2{
                            width: 100%;
                            font-size: 3rem;
                        }
                    }
                    .web-home-products-wrapper {
                        padding-top: 3rem;
                    }
                    .slick-arrow {
                        &.slick-prev {
                            left: 45%;
                        }
                        &.slick-next {
                            right: 45%;
                        }
                    }
                }
                .web-home-vision{
                    h3{
                        font-size: 2rem;
                        font-weight: 900;
                    }
                    .web-home-vison-image{
                        height: 100vh;
                        border-radius: 15px;
                    }
                }
            }
        }
    }
}
