.dashboard {
    .check-circle {
        svg {
            color: $link-green;

            width: 25px;
            height: 25px;
        }
    }
    .close-circle {
        svg {
            color: $link-red;
            width: 25px;
            height: 25px;
        }
    }
    .category_box {
        position: relative;
        cursor: pointer;
        transition: 0.5s all ease-in-out;
        z-index: 0;
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;
            background-color: rgba($color: #000000, $alpha: 0.3);
            opacity: 0;
        }
        &:hover {
            transform: scale(0.96);
            .edit_icon {
                display: block;
            }
            &::after {
                opacity: 1;
            }
        }
        .edit_icon {
            z-index: 2;
            svg {
                width: 40px;
                height: 40px;
                color: #fff;
            }
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: none;
            transition: all 0.5s ease-in-out;
        }
    }
    .active_icon {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }
    .top_icon {
        left: 0.5rem;
    }
    .products-table {
        th {
            position: sticky;
            top: $menubar-height;
            background-color: #fff;
        }
    }
}
.dashboard-card {
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    &:hover {
        transform: scale(1.05);
    }
    position: relative;
    .down-box {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 80%) rotate(45deg);
        width: 50px;
        height: 50px;
        border: 1px solid inherit;
        display: none;
        border-radius: 0 !important;
        border-top-width: 0px;
        border-left-width: 0px;
    }
    &.active {
        .down-box {
            display: block;
        }
    }
}
