.web-wrapper {
    .web-main {
        .web-gallery {
            margin-top: 2rem;
            .gallery-title {
                h2 {
                    width: 100%;
                    text-align: center;
                    color: $link-primary;
                    font-size: 1.5rem;
                    font-weight: 700;
                    margin-bottom: 1rem;
                }
            }
            .web-gallery-item {
                margin-bottom: 2rem;
                border-radius: 15px;
                overflow: hidden;
                position: relative;
                &:hover {
                    img {
                        transform: scale(1.1);
                        transition: all 0.5s ease-in-out;
                    }
                }
                img {
                    border-radius: 15px;
                    transition: all 0.5s ease-in-out;
                }
            }
            .album_thumbnail {
                width: 100%;
                height: 281px;
                display: flex;
                transition: all 0.5s ease-in-out;
                margin-bottom: 2rem;
                border-radius: 15px;
                overflow: hidden;
                position: relative;
                z-index: 0;
                div {
                    flex: 1;
                    transition: all 0.5s ease-in-out;
                    &:first-child,
                    &:nth-child(2n + 3) {
                        filter: grayscale(100%);
                    }
                }
                .album-view-icon {
                    visibility: hidden;
                    color: #fff;
                    font-size: 3rem;
                    z-index: 2;
                    position: absolute;
                    top: 30%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(
                        to top,
                        $link-secondary,
                        rgba($link-secondary, 0.1)
                    );
                }
                &:hover {
                    transform: scale(1.05);
                    transition: all 0.5s ease-in-out;
                    div {
                        transition: all 0.5s ease-in-out;
                        &:first-child,
                        &:nth-child(2n + 3) {
                            filter: grayscale(0%);
                        }
                    }
                }
                .thumbnail-title {
                    z-index: 2;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    width: 90%;
                    word-wrap: break-word;
                    text-align: center;
                    transform: translate(-50%, -50%);
                    h4 {
                        color: #fff;
                        font-size: 1.2rem;
                        font-weight: 800;
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .web-wrapper {
        .web-main {
            .web-gallery {
                margin-top: 3rem;
                .gallery-title {
                    h2 {
                        width: 100%;
                        text-align: center;
                        color: $link-primary;
                        font-size: 3rem;
                    }
                    margin-bottom: 2rem;
                }
            }
        }
    }
}
