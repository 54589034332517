.web-wrapper {
    .web-main {
        .web-products {
            .web-products-wrapper {
                .products-title {
                    margin-top: 2rem;
                    margin-bottom: 1rem;
                    h2 {
                        text-align: center;
                        font-size: 1.5rem;
                        width: 75%;
                        margin: 0 auto;
                        font-weight: 700;
                        color: $link-primary;
                        text-transform: uppercase;
                        span {
                            color: $link-secondary;
                        }
                    }
                }
                .web-products-item {
                    background-color: #fff;
                    border-radius: 15px;
                    border: 1px solid transparent;
                    width: fit-content;
                    position: relative;
                    display: flex !important;
                    justify-content: space-between;
                    align-items: center;
                    overflow: hidden;
                    flex-direction: column;
                    margin: 0 auto;
                    margin-bottom: 2rem;
                    height: 300px;
                    .product-image {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        object-fit: fill;
                        overflow: hidden;
                        img {
                            width: 50%;
                            object-fit: fill;
                            transition: all 1s ease-in-out;
                        }
                    }
                    .product-name {
                        width: 100%;
                        text-align: center;
                        padding: 1rem;
                        span {
                            text-align: center;
                            color: $link-dark;
                        }
                    }
                    border-color: $link-primary;
                }
            }
        }
    }
}
@media (min-width: 992px) {
    .web-wrapper {
        .web-main {
            .web-products {
                .web-products-wrapper {
                    .products-title {
                        margin-top: 2rem;
                        margin-bottom: 2rem;
                        h2 {
                            width: 100%;
                            font-size: 3rem;
                        }
                    }
                }
            }
        }
    }
}
