.auth-bg {
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 1rem;
    font-family: "Roboto", sans-serif;
    position: relative;
    overflow: hidden;
    .auth-logo {
        a {
            img {
                width: 150px;
            }
        }
    }
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -40%);
        z-index: -1;
        height: 30vh;
        width: 100%;
        background-color: $link-secondary;
    }
    .authentication-page-content {
        height: calc(100% - 48px);
        background-color: #fff;
        border-radius: 0;
        border: 1px solid #bbb;
        margin: 24px;
        .auth-icon {
            position: absolute;
            top: 0.4rem;
            &.auth-right-icon {
                right: 0.5rem;
                cursor: pointer;
                &:hover {
                    color: #444;
                }
            }
            &.auth-left-icon {
                left: 0.8rem;
            }
        }
    }
    .form-control {
        display: block;
        width: 100%;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #dbe2f1;
        appearance: none;
        border-radius: 0 !important;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .form-label,
    .form-check-label {
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
    }
    .form-check-input {
        border-radius: 0;
    }
    .text-muted {
        font-size: 14px;
        color: #9397ab !important;
    }
    .btn {
        box-shadow: none;
        outline: none;
    }
    .btn-primary {
        background-color: $link-secondary;
        border-color: $link-secondary;
        padding: 1rem 0;
        border-radius: 0;
    }
}



.c_c_label {
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: $link-dark;
}
.c_c_icon {
    color: $link-gray;
    font-size: 1rem;
    width: 1.5rem;
    height: 1.5rem;
}
.c_c_social_icon {
    color: $link-gray;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
}
.c_c_button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    background-color: $link-secondary;
    font-weight: 600;
    font-size: 0.85rem;
    line-height: 1rem;
    letter-spacing: 1px;
    color: #fff;
    text-transform: uppercase;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.45s;
    border: 2px solid transparent;
    &:hover {
        background-color: #fff;
        border-color: $link-secondary;
        color: $link-secondary;
    }
}
.w-40 {
    width: 10rem;
}
.h-auto {
    height: auto;
}
.web_secondary {
    color: $link-secondary;
}
.web_none {
    display: none !important;
}

.invalid-feedback {
    display: block;
    font-weight: 400;
    font-size: 0.8rem;
}
.btn-site {
    color: $link-secondary;
    background-color: #fff;
    border-color: $link-secondary;
    &:hover {
        color: $link-primary;
        border-color: $link-primary;
    }
}

.dashboard {
    margin-top: $menubar-height;
}
.bg-primary,
.btn-primary {
    background-color: $link-secondary !important;
    border-color: $link-secondary !important;
}
