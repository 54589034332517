.edit-comp-btn,
.album-btn {
    display: none;
}
@media (min-width: 992px) {
    .offcanvas {
        &.offcanvas-end {
            width: 600px;
        }
    }
}
.messages {
    overflow: scroll;
    .message-item {
        position: relative;
        .message-status {
            position: absolute;
            bottom: 0.3rem;
            right: 0.3rem;
            font-size: 0.7rem;
            &.read {
                color: #4a4a4b;
            }
            &.unread {
                color: #52b69a;
            }
            cursor: pointer;
        }
        &:hover {
            background-color: rgba(0, 0, 0, 0.1) !important;
        }
    }
}
.messages_list {
    .message-item {
        transition: all 0.5s ease-in-out;
        position: relative;
    }
}
.border-left-1 {
    border-left: 1px solid rgba(0, 0, 0, 0.15);
}
.logo-hover {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 996;
        background-color: rgba($color: #000000, $alpha: 0.3);
        opacity: 1;
        transition: all 0.5s ease-in-out;
        border: 4px solid $link-secondary;
    }
}
.edit-mode-on {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 996;
        background-color: rgba($color: #000000, $alpha: 0.3);
        opacity: 0;
        transition: all 0.5s ease-in-out;
        border: 4px solid $link-secondary;
    }
    .edit-comp-btn {
        display: block;
        opacity: 0;
        position: absolute;
        top: 10rem;
        left: 50%;
        z-index: 997;
        transform: translate(-50%, -50%);
        transition: all 0.5s ease-in-out;
        i {
            font-size: 2rem;
        }
        padding: 0.5rem 1rem;
        color: #fff;
        background-color: rgba($color: $link-secondary, $alpha: 0.9);
        box-shadow: 6px 6px 4px rgba($color: #000000, $alpha: 0.3);
    }

    &:hover {
        .edit-comp-btn {
            opacity: 1;
        }
        &::after {
            opacity: 1;
        }
    }
    .modal {
        .form-group {
            &.row {
                margin-bottom: 1rem;
            }
        }
    }
}
.edit-mode-on-album {
    position: relative;
    .album-btn {
        display: block;
        opacity: 1;
        position: absolute;
        top: 0;
        right: 1rem;
        z-index: 997;
        padding: 0.5rem 1rem;
    }

    .modal {
        .form-group {
            &.row {
                margin-bottom: 1rem;
            }
        }
    }
}

.modal {
    z-index: 9999 !important;
    .modal-dialog-scrollable {
        .modal-content {
            max-height: 85vh;
            overflow: auto;
        }
    }
    .modal-header--sticky {
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 9999;
    }
    .modal-footer--sticky {
        position: sticky;
        bottom: 0;
        background-color: #fff;
        z-index: 9999;
    }
    .border-left-1 {
        border-left: 1px solid rgba($color: #000000, $alpha: 0.1) !important;
    }
    .icon-center {
        position: relative;
        cursor: pointer;
        .fa-pen-to-square {
            z-index: 1;
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 1s ease-in-out;
            font-size: 2rem;
            color: $link-secondary;
        }
        &::after {
            z-index: 0;
            opacity: 0;
            transition: all 0.5s ease-in-out;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background-color: rgba($color: #000000, $alpha: 0.8);
        }
        &:hover {
            &::after {
                opacity: 1;
            }
            .fa-pen-to-square {
                opacity: 1;
            }
        }
    }
}
.editor-modal {
    &.modal-dialog {
        max-width: 80% !important;
        margin: 1.75rem auto;
        max-height: 50vh;
        img {
            border: 1px solid rgba($color: #000000, $alpha: 0.3);
            padding: 0.3rem;
            border-radius: 5px;
        }
        .facility-image {
            img {
                border: none;
                padding: 0;
                border-radius: 0;
            }
        }
        .social-icons {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            li {
                width: 100%;
                border: 1px solid rgba($color: #000000, $alpha: 0.3);
                padding: 0 0.5rem;
                margin-top: 1rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                i {
                    color: $link-primary;
                    font-size: 1.3rem;
                    text-align: center;
                    flex: 0.1;
                }
                input {
                    border: none;
                    box-shadow: none;
                    outline: none;
                    flex: 0.8;
                    border-left: 1px solid rgba($color: #000000, $alpha: 0.3);
                    padding: 0.5rem 1rem;
                }
                span {
                    font-size: 0.9rem;

                    text-align: center;
                }
            }
        }
    }
}

.menubar {
    display: flex;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9997;
    height: $menubar-height;
    background-color: #ececec;
    border-bottom: 2px solid rgb(216, 202, 1);
    transition: all 0.5s;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    padding-left: 20px;
    /* Toggle Sidebar Button */
    /* Search Bar */

    @media (min-width: 1200px) {
        .logo {
            width: 280px;
        }
    }
    .logo .logo-img {
        max-height: 40px;
        margin-right: 6px;
    }
    .logo .logo-title {
        font-size: 1.2rem;
        font-weight: 800;
        color: #0d659d;
        margin: auto 0;
    }
    .menubar-nav {
        ul {
            list-style: none;
            li {
                &:hover {
                    & > a {
                        i {
                            color: $link-secondary !important;
                        }
                    }
                }
            }
        }
        & > ul {
            margin: 0;
            padding: 0;
        }
        .nav-icon {
            font-size: 20px;
            color: $link-primary;
        }
        .nav-profile {
            color: $link-primary;
            img {
                max-height: 36px;
            }
            span {
                font-size: 14px;
                font-weight: 600;
            }
        }
        .badge-number {
            position: absolute;
            inset: 4px 6px auto auto;
            font-weight: normal;
            font-size: 11px;
            padding: 3px 6px;
        }
        .messages {
            .message-item {
                padding: 15px 10px;
                transition: 0.3s;
                a {
                    display: flex;
                }
                i {
                    margin: 0 20px 0 10px;
                    max-height: 40px;
                }
                h4 {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 5px;
                    color: #444444;
                }
                p {
                    font-size: 13px;
                    margin-bottom: 3px;
                    color: #919191;
                }
                &:hover {
                    background-color: #f6f9ff;
                }
            }
            &.dropdown-menu {
                min-width: 20rem;
            }
        }
        .profile {
            min-width: 240px;
            padding-bottom: 0;
            .dropdown-menubar {
                h6 {
                    font-size: 18px;
                    margin-bottom: 0;
                    font-weight: 600;
                    color: #444444;
                }
                span {
                    font-size: 14px;
                }
            }
            .dropdown-item {
                font-size: 14px;
                padding: 10px 15px;
                transition: 0.3s;
                i {
                    margin-right: 10px;
                    font-size: 18px;
                    line-height: 0;
                    color: $link-primary;
                }
                &:hover {
                    background-color: #f6f9ff;
                }
            }
        }
        .dropdown-menu {
            border-radius: 4px;

            padding: 10px 0;
            -webkit-animation-name: dropdown-animate;
            animation-name: dropdown-animate;
            -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            border: 0;
            box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
            .dropdown-header,
            .dropdown-footer {
                text-align: center;
                font-size: 15px;
                padding: 10px 25px;
            }
            .dropdown-footer a {
                color: #444444;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
            .dropdown-divider {
                color: #a5c5fe;
                margin: 0;
            }
            .dropdown-item {
                font-size: 14px;
                padding: 10px 15px;
                transition: 0.3s;
                i {
                    margin-right: 10px;
                    font-size: 18px;
                    line-height: 0;
                }
                &:hover {
                    background-color: #f6f9ff;
                }
            }
        }
    }
    .menubar-navlinks {
        .web-nav-link {
            color: $link-gray;
            padding: 0.3rem 0;
            border-bottom: 2px solid transparent;
            &:hover {
                color: $link-secondary;
            }

            &.active {
                border-bottom: 2px solid $link-secondary;
                color: $link-dark;
                i {
                    color: $link-secondary;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .menubar {
        .menubar-nav {
            .dropdown-menu-arrow::before {
                content: "";
                width: 13px;
                height: 13px;
                background: #fff;
                position: absolute;
                top: -7px;
                right: 20px;
                transform: rotate(45deg);
                border-top: 1px solid #e0e0e0;
                border-left: 1px solid #e0e0e0;
            }
        }
    }
}

@-webkit-keyframes dropdown-animate {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
    0% {
        opacity: 0;
    }
}
@keyframes dropdown-animate {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
    0% {
        opacity: 0;
    }
}
.dashboard-menu-toggler {
    display: none;
}
.editor_website {
    margin-top: $menubar-height;
    .web_offcanvas {
        top: $menubar-height;
    }
}
@media (min-width: 992px) {
    .editor_website {
        padding-top: 0.5rem;
        .web_nav {
            top: $menubar-height;
            &.web_nav_sticky {
                top: $menubar-height;
            }
        }
    }
}
@media (max-width: 992px) {
    .dashboard-menu-toggler {
        display: block;
    }

    .menubar {
        .menubar-nav {
            .messages {
                &.dropdown-menu {
                    min-width: 20rem;
                    transform: translate3d(37px, 43px, 0px) !important;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .web_editor {
        margin-top: $web-nav-height;
        .web_banner {
            margin-top: 0;
        }
    }
}
