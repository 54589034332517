/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $link-primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba($color: $link-primary, $alpha: 0.5);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}
a {
    text-decoration: none;
}

.fa-brands {
    line-height: unset;
}
.carousel-control-prev,
.carousel-control-next {
    top: 50%;
    bottom: unset;
    width: unset;
}

.carousel-control-prev{
    transform: translate(50%,-50%);
}
.carousel-control-next {
    transform: translate(-50%,-50%);
}
