.web-banner {
    .web-banner-wrapper {
        width: 100%;
        height: 200px;
        background-color: $link-secondary;
        margin-top: -10px;
        position: relative;
        background-repeat: no-repeat;
        background-position: center left;
        background-size: cover;
        .banner-text {
            width: inherit;
            height: inherit;
            position: absolute;
            top: 70%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h1 {
                font-size: 3rem;
                font-weight: 700;
                color: #fff;
                font-family: $font-family-caveat;
            }
            color: #fff;
            text-transform: capitalize;
            a{
                color: $link-dark;
                font-weight: 700;
                text-transform: capitalize;
            }

        }
    }
}
@media(min-width:992px){
    .web-banner {
        .web-banner-wrapper {
            height: 300px;
            background-position: center center;
            background-size: cover;
            .banner-text {
                top: 60%;
                h1 {
                    font-size: 5rem;
                    font-weight: 800;
                }
            }
        }
    }
}
