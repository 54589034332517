.scroll-to-top {
    bottom: 3rem;
    right: 1rem;
    z-index: 9999;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(10rem);
    transition: all 0.5s ease-in-out;
    &.visible {
        transform: translateY(0);
    }
    span {
        background-color: $link-secondary;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        border-radius: 100%;

        i{
            color: #fff;
            font-size: 2rem;
            font-weight: 800;
        }
    }
}
.font-family-caveat{
    font-family: $font-family-caveat;
}
