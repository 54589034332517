.web-wrapper {
    .web-main {
        .web-contact {
            margin-top: 3rem;
            .web-contact-card {
                padding: 1rem;
                border-radius: 15px;
                h2 {
                    font-size: 2rem;
                    font-weight: 800;
                    color: $link-secondary;
                    font-family: $font-family-caveat;
                    text-align: center;
                    width: 100%;
                }
                .web-contact-info {
                    padding: 0 1.5rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    h3 {
                        font-weight: 800;
                        font-size: 2rem;
                        margin-top: 1rem;
                    }
                    .web-contact-info-item {
                        margin: 1rem 0;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 1rem;
                        width: 100%;
                        color: $link-dark;
                        a {
                            color: $link-dark;
                            &:hover {
                                color: $link-secondary;
                            }
                        }
                        .web-contact-info-item-icon {
                            min-width: 40px;
                            height: 40px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            i {
                                font-size: 2rem;
                                color: $link-secondary;
                            }
                        }
                        h4 {
                            font-weight: 700;
                            font-size: 1.5rem;
                        }
                    }
                }

                .web-contact-form {
                    input[type="text"] {
                        text-transform: capitalize;
                    }
                    .form-fields{
                        div:nth-child(4){
                            display: none;
                        }
                    }

                    input,
                    textarea {
                        width: 100%;
                        padding: 1rem 1.5rem;
                        border-radius: 5px;
                        border: none;
                        outline: 0;
                        transition: 0.5s all ease-in-out;
                        background-color: $link-smoke;
                        margin: 0.5rem 0;
                        &::placeholder {
                            color: rgba($link-dark, 50%);
                        }
                        &:focus {
                            transition: 0.5s all ease-in-out;
                            box-shadow: 0 0 0 0.2rem rgba($link-secondary, 25%);
                        }
                    }
                    textarea {
                        resize: none;
                    }
                    .web-contact-form-submit {
                        width: 100%;
                        background-color: $link-dark;

                        position: relative;
                        overflow: hidden;
                        z-index: 0;
                        padding: 1rem 1.5rem;
                        border-radius: 5px;
                        border: none;
                        outline: 0;
                        span{
                            position: relative;
                            color: #fff;
                            display: block;
                            z-index: 2;
                        }
                        &:hover {
                            &::before {
                                transition: transform 0.5s ease-in-out;
                                transform: translateX(-120%) skewX(60deg);
                            }
                            &::after {
                                transition: transform 0.5s ease-in-out;
                                transform: translateX(120%) skewX(60deg);
                            }
                            i {
                                transition: all 0.5s ease-in-out;
                                color: $link-primary;
                            }
                            strong {
                                transition: all 0.5s ease-in-out;
                                color: $link-secondary;
                            }
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 55%;
                            height: 100%;
                            background-color: $link-secondary;
                            z-index: 1;
                            transition: transform 0.5s ease-in-out;
                        }
                        &::after {
                            content: "";
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 55%;
                            height: 100%;
                            background-color: $link-secondary;
                            z-index: 1;
                            transition: transform 0.5s ease-in-out;
                        }
                    }
                }
            }
            .web-contact-map {
                margin-top: 3rem;
                iframe {
                    width: 100%;
                    height: 350px;
                }
                line-height: 0;
            }
        }
    }
}

@media (min-width: 992px) {
    .web-wrapper {
        .web-main {
            .web-contact {
                .web-contact-card {
                    h2 {
                        font-size: 3rem;
                        text-align: left;
                    }
                    .web-contact-info {
                        padding: 0 1.5rem;
                        border-right: 2px solid $link-primary;
                        h3 {
                            font-size: 2rem;
                        }
                    }
                    .web-contact-form{
                        .web-contact-form-submit{
                            width: fit-content;
                        }
                    }
                }
            }
        }
    }
}
